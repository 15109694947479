import { useState } from 'react';

function useSettings() {
    const [muted, setMuted] = useState(false);

    return {
        muted,
        setMuted
    };
}

export default useSettings;
