import styles from './Overlay.module.css';

function Overlay({ children, open }) {
    if (!open) {
        return null;
    }

    return (
        <div className={styles.root}>
            {children}
        </div>
    );
}

export default Overlay;
