import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './CardGroup.module.css';

function CardGroup({ children, highlighted }) {
    const ref = useRef();
    useEffect(() => {
        if (highlighted) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [highlighted]);

    const className = cx(styles.root, { [styles.withBorder]: React.Children.count(children) > 1 });

    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    );
}

export default CardGroup;
