import { useHistory } from 'react-router-dom';
import { Button, Text } from '../../components';
import styles from './NotFoundPage.module.css';

function NotFoundPage() {
    const history = useHistory();

    return (
        <div className={styles.root}>
            <h1>Nope.</h1>
            <Text>Your friends gave you a fake link to get you to buzz off. Or maybe you're just bad at typing. Either way: yikes.</Text>
            <Button onClick={() => history.push('/')} text="← Back to home page" textOnly />
        </div>
    );
}

export default NotFoundPage;
