import { ReactComponent as Copy } from '../../assets/copy.svg';
import { ReactComponent as Share } from '../../assets/share.svg';
import { ReactComponent as SoundOn } from '../../assets/sound_on.svg';
import { ReactComponent as SoundOff } from '../../assets/sound_off.svg';

const icons = {
    copy: Copy,
    share: Share,
    soundOn: SoundOn,
    soundOff: SoundOff
};

export default icons;
