import styles from './Callout.module.css';

function Callout({ children, isOpen }) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.root}>
            {children}
        </div>
    );
}

export default Callout;
