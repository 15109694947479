import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GamePage, HomePage, NotFoundPage } from './pages';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route exact path="/:id([A-Za-z]{4})">
                    <GamePage />
                </Route>
                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
