import { Icon } from '..';
import styles from './IconButton.module.css';

function IconButton({ hint, iconName, onClick }) {
    return (
        <button className={styles.root} aria-label={hint} onClick={onClick}>
            <Icon iconName={iconName} />
        </button>
    );
}

export default IconButton;
