import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import judgeAudioFile from '../../assets/judge.wav';
import { Button, Card, CardGroup, IconButton, Input, Overlay, Player, Text } from '../../components';
import CopyInput from '../../components/Input/CopyInput';
import { GameStatus, useGame, useSettings } from '../../util';
import { NotFoundPage } from '..';
import styles from './GamePage.module.css';

const judgeAudio = new Audio(judgeAudioFile);

function GamePage() {
    const { id } = useParams();
    const prevGameId = sessionStorage.getItem('game');
    const name = sessionStorage.getItem('name');
    const [isNewGame, setIsNewGame] = useState(id !== prevGameId);
    const join = (name) => {
        sessionStorage.setItem('game', id);
        sessionStorage.setItem('name', name);
        setIsNewGame(false);
    };

    if (!name || isNewGame) {
        return <Join onSubmit={join} />;
    }

    return <Game id={id} name={name} />;
}

function Join({ onSubmit: submit }) {
    const [name, setName] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        if (name.length > 0) {
            submit(name);
        }
    };

    return (
        <div className={styles.join}>
            <div className={styles.inner}>
                <div className={styles.row}>
                    <form onSubmit={onSubmit}>
                        <Input onChange={setName} placeholder="Name" value={name} autoFocus />
                        <Button disabled={!name} text="Join" fill />
                    </form>
                </div>
                <div className={styles.row}>
                    <Text><span className="bold">Invite your friends.</span> Or enemies. Anyone you want to play with, really.</Text>
                    <CopyInput value={window.location.href} />
                </div>
            </div>
        </div>
    );
}

function Game({ id, name }) {
    const settings = useSettings();
    const { state, status, play, judge } = useGame(id, name);
    const [selected, setSelected] = useState([]);

    const prevStage = useRef(state.stage);
    if (!settings.muted && state.czar && state.stage === 'judge' && prevStage.current !== 'judge') {
        judgeAudio.play();
    }
    prevStage.current = state.stage;

    const onCardClick = (index) => {
        if (state.stage !== 'play') {
            return;
        }

        if (selected.includes(index)) {
            setSelected(selected.filter(selectedIndex => selectedIndex !== index));
        } else {
            const next = [...selected, index];
            if (next.length === state.blackCard.num) {
                setSelected([]);
                play(next);
            } else {
                setSelected(next);
            }
        }
    }

    if (status === GameStatus.CONNECTING) {
        return <h1>Connecting...</h1>;
    }

    if (status === GameStatus.NOTFOUND) {
        return <NotFoundPage />;
    }

    return (
        <>
        {/* <div className={styles.header}> */}
            <div className={styles.players}>
                {state.players.map((player, index) => (
                    <Player
                        key={index}
                        czar={player.czar}
                        disconnected={!player.connected}
                        highlighted={player.selected}
                        name={player.name}
                        played={player.played}
                        points={player.score}
                    />
                ))}
            </div>
            {/* <div>
                <IconButton
                    hint="Share game link"
                    iconName="share"
                    onClick={() => navigator.clipboard.writeText(window.location.href)}
                />
                <IconButton
                    hint={settings.muted ? "Muted" : "Unmuted"}
                    iconName={settings.muted ? "soundOff" : "soundOn"}
                    onClick={() => settings.setMuted(!settings.muted)}
                />
            </div> */}
        {/* </div> */}
            <div className={styles.played}>
                <CardGroup><Card black text={state.blackCard.text} /></CardGroup>
                <div className={styles.cards}>
                    {state.played.map(({ cards, selected }, index) => (
                        <CardGroup key={index} highlighted={selected}>
                            {cards.map(card => (
                                <Card
                                    key={card.text}
                                    highlighted={selected}
                                    onClick={() => judge(index)}
                                    text={card.text}
                                />
                            ))}
                        </CardGroup>
                    ))}
                </div>
            </div>
            <div className={styles.cards}>
                {state.hand.map((card, index) => (
                    <Card
                        key={card.text}
                        badge={selected.findIndex(selectedIndex => selectedIndex === index) + 1}
                        disabled={state.czar}
                        onClick={() => onCardClick(index)}
                        text={card.text}
                    />
                ))}
            </div>
            <footer className={styles.footer}>
                <Text variant="footnote">
                    <a href="https://cardsagainsthumanity.com">Cards Against Humanity</a> content used under the <a href="https://creativecommons.org/licenses/by-nc-sa/2.0/">Creative Commons BY-NC-SA 2.0 license</a>.
                    Sounds by <a href="https://material.io/design/sound/sound-resources.html">Google</a> used under the <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons BY 4.0 license</a>.
                </Text>
            </footer>
            <Overlay open={status === GameStatus.RECONNECTING}>
                <h1>Reconnecting...</h1>
            </Overlay>
        </>
    );
}

export default GamePage;
