import { Button, Text } from '../../components';
import styles from './HomePage.module.css';

function HomePage() {
    const newGame = (e) => {
        e.preventDefault();
        fetch('/api/games', { method: 'POST' })
        .then(res => res.text())
        .then(id => window.location.href = '/' + id);
    };

    return (
        <div className={styles.root}>
            <h1>Cards Against</h1>
            <h2>(...no one in particular)</h2>
            <p>A Cards Against Humanity clone.</p>
            <form className={styles.config} onSubmit={newGame}>
                <Button text="Start New Game" />
            </form>
            <p>Trying to join a game already in progress? Your friends should have a link for you.</p>
            <p><a href="https://cardsagainsthumanity.com">Cards Against Humanity</a> content is gratefully used under the terms of the <a href="https://creativecommons.org/licenses/by-nc-sa/2.0/">Creative Commons BY-NC-SA 2.0 license</a> and, in turn, this work is published under the same license.</p>
            <p>While using this site, your IP address, the name you use within the game, and the cards you choose to play may be logged.</p>
            <footer className={styles.footer}>
                <Text variant="footnote">A <a href="https://cardsagainsthumanity.com">Cards Against Humanity</a> clone. This site is not created, sponsored, nor endorsed by <a href="https://cardsagainsthumanity.com">Cards Against Humanity</a>.</Text>
                <div style={{ margin: '8px auto'}}>
                    <a rel="license" href="https://creativecommons.org/licenses/by-nc-sa/2.0/">
                        <img alt="Creative Commons License" src="https://mirrors.creativecommons.org/presskit/buttons/88x31/svg/by-nc-sa.svg"/>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default HomePage;
