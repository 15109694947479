import React from 'react';
import cx from 'classnames';
import styles from './Text.module.css';

function Text({ as, children, variant }) {
    const className = cx(styles.root, { [styles.footnote]: variant === 'footnote' });

    return React.createElement(as ?? 'p', { className }, children);
}

export default Text;
