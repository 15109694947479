import { useCallback, useState } from 'react';
import { useWebSocket } from '.';

export const Status = {
    CONNECTED: Symbol('Connected'),         // Connected
    CONNECTING: Symbol('Connecting'),       // Making initial connection to game server
    NOTFOUND: Symbol('Not found'),          // The requested game does not exist
    RECONNECTING: Symbol('Reconnecting')    // Restoring a previously initialized connection
};

function useGame(gameId, name) {
    const [state, setState] = useState({});
    const [status, setStatus] = useState(Status.CONNECTING);

    const onMessage = useCallback((payload) => {
        const [type, message] = payload;
        if (type === 'state') {
            setState(message);
            setStatus(Status.CONNECTED);
        } else if (type === 'status') {
            if (message.code === 'notfound') {
                setStatus(Status.NOTFOUND);
            } else if (message.code === 'ok') {
                setStatus(Status.CONNECTED);
            }
        }
    }, []);
    const socket = useWebSocket(`wss://${window.location.host}/api/games/${gameId}?name=${name}`, onMessage);

    const judge = (cards) => {
        if (state.stage !== 'judge') {
            return;
        }

        socket.send(['judge', cards]);
    };

    const play = (cards) => {
        if (state.stage !== 'play') {
            return;
        }

        socket.send(['play', cards]);
    };

    return { state, status, judge, play };
}

export default useGame;
