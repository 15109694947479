import { useEffect, useRef } from "react";

function useWebsocket(url, onMessage) {
    const socketRef = useRef();
    useEffect(() => {
        const socket = new WebSocket(url);
        socket.addEventListener('message', message => onMessage(JSON.parse(message.data)));
        socketRef.current = socket;
        return () => socket.close();
    }, [onMessage, url]);

    const send = (message) => {
        socketRef.current?.send(JSON.stringify(message));
    };

    return { send };
}

export default useWebsocket;
