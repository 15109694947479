import cx from 'classnames';
import styles from './Button.module.css';

function Button({ disabled, fill, onClick, text, textOnly }) {
    const className = cx(styles.root, {
        [styles.disabled]: disabled,
        [styles.fill]: fill,
        [styles.textOnly]: textOnly
    });

    return (
        <button className={className} disabled={disabled} onClick={onClick}>{text}</button>
    );
}

export default Button;
