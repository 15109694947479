import cx from 'classnames';
import styles from './Card.module.css';

function Card({ badge, black, disabled, highlighted, onClick, text }) {
    const className = cx(styles.root, {
        [styles.black]: black,
        [styles.disabled]: disabled,
        [styles.highlighted]: highlighted
    });

    return (
        <div className={className} onClick={onClick}>
            {!!badge && <span className={styles.badge}>{badge}</span>}
            <span>{text}</span>
        </div>
    );
}

export default Card;
