import { useRef } from 'react';
import { IconButton } from '..';
import styles from './Input.module.css';

function CopyInput({ value }) {
    const ref = useRef();

    const onFocus = () => {
        ref.current.select();
    }

    const onClick = () => {
        navigator.clipboard.writeText(value);
        onFocus();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <input ref={ref} className={styles.root} onFocus={onFocus} value={value} />
            <IconButton hint="Copy link" iconName="copy" onClick={onClick} />
        </div>
    );
}

export default CopyInput;
