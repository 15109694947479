import styles from './Input.module.css';

function Input({ autoFocus, onChange, placeholder, value }) {
    return (
        <input
            className={styles.root}
            autoFocus={autoFocus}
            onChange={e => onChange?.(e.target.value)}
            placeholder={placeholder}
            type="text"
            value={value}
        />
    );
}

export default Input;
