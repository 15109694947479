import { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './Player.module.css';

function Player({ czar, disconnected, highlighted, name, played, points }) {
    const ref = useRef();
    useEffect(() => {
        if (highlighted) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [highlighted]);

    const className = cx(styles.root, {
        [styles.black]: czar,
        [styles.disconnected]: disconnected,
        [styles.highlighted]: highlighted,
        [styles.white]: played && !czar
    });

    return (
        <div ref={ref} className={className}>
            <span className={styles.name}>{name}</span>
            <span className={styles.points}>{points} points</span>
        </div>
    );
}

export default Player;
